<template>
  <section>
    <Dialog headerClass="text-xs" :closable="false" v-model:visible="medicamentosStore._display_ver_medicamento" :style="{width: '40vw'}" :modal="true">
      <template #header>
        <div class="flex justify-between w-full">
          <p class="font-bold">Información del artículo {{ medicamentosStore._info_medicamento.ItemName }}</p>
          <button @click="medicamentosStore.display_ver_medicamento = false"><i class="pi pi-times"></i></button>
        </div>
      </template>
      <div class="grid grid-cols-2 gap-4">
        <p class="text-xs">Código de Barras:</p>
        <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.CodeBars }}</p>
        <p class="text-xs">Nombre:</p>
        <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.ItemName }}</p>
        <p class="text-xs">Laboratorio:</p>
        <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.U_PHR_Lab }}</p>
        <p class="text-xs">Ud-Ref:</p>
        <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.U_PHR_UdREF }}</p>
        <p class="text-xs">Forma Farmacéutica:</p>
        <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.U_PHR_FormFarma }}</p>
        <p class="text-xs">Principio Activo:</p>
        <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.U_PHR_PrincActivo }}</p>
        <p class="text-xs">Unidad Principio Activo:</p>
        <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.U_PHR_UndMedida }}</p>
        <p class="text-xs">Cant. Principio Activo:</p>
        <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.U_PHR_CantPrincAct }}</p>
        <p class="text-xs">Comentario:</p>
        <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.UserText }}</p>
        <p class="text-xs">CUM:</p>
        <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.SWW }}</p>
        <p class="text-xs">Agrupado código y nombre:</p>
        <p class="font-medium text-xs">{{ '(' + medicamentosStore._info_medicamento.IdGroup + ') - ' + medicamentosStore._info_medicamento.GrpName }}</p>
      </div>
      <div class="mt-4" v-if="medicamentosStore._info_medicamento.cartaAgotado && medicamentosStore._info_medicamento.cartaAgotado.fecha_carta">
        <div class="flex items-center gap-2">
          <p class="font-bold mb-2">Carta de agotado</p>
          <Button text rounded icon="pi pi-download" size="small" @click="descargarCartaAgotado(medicamentosStore._info_medicamento.cartaAgotado.id)" aria-label="Descargar" />
        </div>
        <div class="w-full grid grid-cols-2 gap-4">
          <p class="text-xs">Fecha Carta:</p>
          <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.cartaAgotado.fecha_carta }}</p>
          <p class="text-xs">Fecha Recepción:</p>
          <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.cartaAgotado.fecha_recepcion }}</p>
          <p class="text-xs">Fecha Mx Disponible:</p>
          <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.cartaAgotado.fecha_mx_disponible ? medicamentosStore._info_medicamento.cartaAgotado.fecha_mx_disponible : 'Por confirmar'  }}</p>
          <p class="text-xs">Laboratorio:</p>
          <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.cartaAgotado.laboratorio }}</p>
          <p class="text-xs">Estado:</p>
          <p class="font-medium text-xs">{{ medicamentosStore._info_medicamento.cartaAgotado.estado }}</p>
        </div>
      </div>
    </Dialog>
  </section>
</template>
<script>
  import { useMedicamentosStore } from '../stores/medicamentos.store'
  export default {
    name: 'verMedicamento',
    setup () {
      const medicamentosStore = useMedicamentosStore()
      const descargarCartaAgotado = (id) => {
        medicamentosStore.descargarCargaAgotado(id)
      }
      return {
        medicamentosStore,
        descargarCartaAgotado
      }
    }
  }
</script>
<style>
  ::v-deep(.p-dropdown ) {
    height: 1.8rem;
    padding: 0rem;
  }
  ::v-deep(.p-dropdown .p-inputtext) {
    font-size: 0.75rem;
    padding: 0.3rem;
  }
  ::v-deep(.p-inputtext) {
    font-size: 0.75rem;
    padding: 0.25rem;
  }
  ::v-deep(.chevron-dropdown-none .p-dropdown-trigger) {
    display: none !important;
  }
  :global(.swal2-container) {z-index: 1000000 !important}
</style>
