<template>
  <section>
    <div class="w-full mt-4 shadow p-2 rounded-md">
      <div class="w-full">
        <div class="flex gap-2">
          <button @click="show = !show" class="flex gap-2">
            <i v-if="show" class="pi pi-chevron-down text-blue-800 text-xs"></i>
            <i v-else class="pi pi-chevron-right text-blue-800 text-xs"></i>
            <p class="text-blue-800 font-bold">ARTÍCULOS</p>
          </button>
          <button @click="toggle">
            <i class="pi pi-info-circle text-blue-800"></i>
          </button>
          <OverlayPanel ref="op">
            <div class="w-full grid grid-cols-1 gap-1">
              <div class="gap-1 flex items-center">
                <div class="w-4 h-4 bg-red-100 rounded-full"></div>
                <p class="text-xs">Artículo sin contrato precio o apartado sin precio</p>
              </div>
              <div class="gap-1 flex items-center">
                <div class="w-4 h-4 bg-green-100 rounded-full"></div>
                <p class="text-xs">Artículo en stock de disponibles</p>
              </div>
              <div class="gap-1 flex items-center">
                <div class="w-4 h-4 bg-yellow-200 rounded-full"></div>
                <p class="text-xs">Artículo en stock de apartados</p>
              </div>
            </div>
          </OverlayPanel>
        </div>
        <div class="my-2" v-if="show">
          <div class="flex">
            <div class="w-full">
              <AutoComplete
                @item-select="validarIngresoMedicamento(medicamentoSeleccionado)"
                v-model="medicamentoSeleccionado"
                placeholder="Buscar artículo (mínimo 3 caracteres)..."
                id="inputElement"
                inputClass="w-full text-xs"
                v-tooltip.top="'Para activar el buscador (Ctrl + y)'"
                panelClass="text-xs h-panel"
                class="p-inputtext-sm my-4 w-full text-xs"
                :suggestions="medicamentosStore._listado_medicamentos"
                autofocus
                @complete="buscarMedicamentos($event)"
                :forceSelection="true"
                optionLabel="name"
              >
              <template #item="slotProps">
                <div
                  class="flex gap-2 items-center p-1 rounded-md bg-green-100"
                >
                  <img class="w-10" alt="Imagen del artículo" :src="`${baseUrl}/articulos/get-image/${slotProps.item.PicturName}`">
                  <div v-if="slotProps.item.cantstock">
                    <p class="p-0">{{
                        slotProps.item.CodMx + ' - ' + slotProps.item.ItemName + slotProps.item.UserText
                      }}</p>
                    <div class="flex items-center gap-2">
                      <p class="font-bold">Mx:</p>
                      <p class="font-medium">{{ slotProps.item.NumLote }}</p>
                      <p class="font-bold">Cant Stock:</p>
                      <p class="font-medium">{{ slotProps.item.cantstock }}</p>
                      <p class="font-bold">Precio:</p>
                      <p :class="slotProps.item.Price ? 'text-green-600' : ''"
                          class="font-medium">{{
                          slotProps.item.Price ? $h.formatCurrency(slotProps.item.Price) : '...ESTE ARTÍCULO NO CUENTA CON CONTRATO PRECIO'
                        }}
                      </p>
                      <p class="font-bold">Cum:</p>
                      <p class="font-medium">{{ slotProps.item.SWW }}</p>
                    </div>
                  </div>
                  <div v-else>
                    <p class="p-0">{{ slotProps.item.CodMx + ' - ' + slotProps.item.ItemName }}</p>
                    <div class="flex gap-2">
                      <p class="font-bold">Lote:</p>
                      <p class="font-medium">{{ slotProps.item.NumLote ? slotProps.item.NumLote : 'NO TIENE'  }}</p>
                      <p class="font-bold">Cant Stock:</p>
                      <p class="font-medium">0</p>
                    </div>
                  </div>
                </div>
              </template>
              </AutoComplete>
            </div>
            <div class="w-min flex justify-end">
              <button v-if="lineasMdsSeleccionadas.length || lineasLotesSeleccionados.length" @click="eliminarMedicamentos"><i class="pi pi-trash text-red-600 mx-4 text-xs"></i></button>
            </div>
          </div>
          <DataTable
            :value="medicamentosStore._medicamentos_tabla"
            dataKey="ItemCode"
            class="p-datatable-sm"
            editMode="cell"
            v-model:selection="lineasMdsSeleccionadas"
            responsiveLayout="scroll">
            <template #empty>
              <p class="text-xs">
                No se encontraron artículos, ingrese uno con Ctrl + y, para iniciar.
              </p>
            </template>
            <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
            <!-- <Column :expander="true" headerStyle="width: 3em" >
              <template #header>
                <button v-if="expandedRows && expandedRows.length" @click="expandedRows = null"><i class="pi pi-angle-down"></i></button>
                <button v-else @click="expandedRows = medicamentosStore._medicamentos_tabla.filter(a => a.ItemCode)"><i class="pi pi-angle-right"></i></button>
              </template>
            </Column> -->
            <Column header="Código mipres" v-if="validarColumnasTabla('U_PHR_CodMipres')" class="text-xs" headerClass="text-xs"  style="min-width: 16rem; max-width: 18rem">
              <template #body="slotProps">
                <Dropdown
                  @change="obtenerNumerosDireccionamiento"
                  panelClass="text-xs"
                  optionLabel="NoPrescripcion"
                  v-model="slotProps.data.U_PHR_CodMipres"
                  class="w-full border rounded-md"
                  :options="codigosMipres"
                  :class="validacionCampoRequerido('U_PHR_CodMipres', slotProps.data.U_PHR_CodMipres) ? 'border-red-600' : 'border-gray-300'"
                />
                <span v-if="validacionCampoRequerido('U_PHR_CodMipres', slotProps.data.U_PHR_CodMipres)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Num. Direccionamiento" v-if="validarColumnasTabla('U_PHR_NumDirec')" class="text-xs" headerClass="text-xs"  style="min-width: 16rem; max-width: 18rem">
              <template #body="slotProps">
                <Dropdown
                  panelClass="text-xs"
                  optionLabel="name"
                  optionValue="IDDireccionamiento"
                  v-model="slotProps.data.U_PHR_NumDirec"
                  class="w-full border border-gray-300 rounded-md"
                  :options="numerosDireccionamiento"
                  :class="validacionCampoRequerido('U_PHR_NumDirec', slotProps.data.U_PHR_NumDirec) ? 'border-red-600' : 'border-gray-300'"
                ></Dropdown>
                <span v-if="validacionCampoRequerido('U_PHR_NumDirec', slotProps.data.U_PHR_NumDirec)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Articulo" class="text-xs" headerClass="text-xs" style="min-width: 20rem">
              <template #body="slotProps">
                <div class="w-full flex gap-2">
                  <button v-tooltip.top="'Ver pendiente'" class="w-1/12" @click="abrirModalPendiente(slotProps.data)" v-if="slotProps.data.OpenCreQty"><i class="pi pi-exclamation-circle text-red-600"></i></button>
                  <div class="w-full">
                    <div class="flex gap-2 w-full">
                      <span class="flex gap-2 items-center w-full">
                        <div class="w-10/12">
                          {{ slotProps.data.ItemCode + ' - ' + slotProps.data.ItemName }}
                        </div>
                        <div class="w-2/12 flex justify-end gap-2">
                          <button v-tooltip.top="'Ver artículo'" @click="obtenerDetalleMedicamento(slotProps.data)">
                            <i class="pi pi-eye text-blue-600"></i>
                          </button>
                          <!-- <button v-tooltip.top="'Reemplazar artículo'" @click="validarReemplazarMedicamentoAdvertencia(slotProps.data)">
                            <i class="pi pi-replay text-green-600"></i>
                          </button> -->
                        </div>
                      </span>
                    </div>
                    <div class="flex gap-2">
                      <p class="font-bold">Stock total:</p><p>{{ slotProps.data.OnHandQty }}</p>
                      <p class="font-bold">Precio:</p><p class="font-bold text-green-600">{{ $h.formatCurrency(parseInt(slotProps.data.UnitPrice)) }}</p>
                      <p>
                        {{ slotProps.data.unidadSeleccionada.description }}
                        <i
                          v-tooltip.top="'Cambiar Unidad'"
                          v-if="slotProps.data.unidades_medida.length > 1"
                          @click="modalEditUnidades = true, unidadEditar = slotProps.data.ItemCode"
                          class="pi pi-replay text-red-600 cursor-pointer ml-3 text-sm">
                        </i>
                        <Dialog v-if="unidadEditar === slotProps.data.ItemCode" headerClass="text-xs" header="Unidad de Medida" :closable="false" v-model:visible="modalEditUnidades" :style="{width: '30vw'}" :modal="true">
                          <div class="text-xs flex justify-center">
                            <Dropdown
                              panelClass="text-xs"
                              v-model="slotProps.data.unidadSeleccionada"
                              class="w-2/4 border border-gray-300 rounded-md"
                              optionLabel="description"
                              :options="slotProps.data.unidades_medida"
                              :class="validacionCampoRequerido('UseBaseUnits', slotProps.data.UseBaseUnits) ? 'border-red-600' : 'border-gray-300'"
                            />
                          </div>
                          <template #footer>
                            <Button @click="modalEditUnidades = false" label="Aceptar" />
                          </template>
                        </Dialog>
                      </p>
                      <p class="font-bold">Cum:</p><p>{{ slotProps.data.SWW }}</p>
                    </div>
                    <span v-if="slotProps.data.Quantity === 0 && !slotProps.data.OnHandQty && creandoOrden" class="italic text-red-600 text-xs w-full ml-2">Este artículo debe tener un pendiente asignado</span>
                  </div>
                </div>
              </template>
            </Column>
            <Column header="Cantidad" class="text-xs" headerClass="text-xs" style="min-width: 8rem; max-width: 10rem">
              <template #body="slotProps">
                <div class="gap-0 text-xs grid grid-cols-2">
                  <!-- <p class="font-bold">Ent.</p>
                  <p class="font-bold text-green-600">{{ slotProps.data.DelivrdQty }}</p>
                  <p class="font-bold">Pend.</p>
                  <p class="font-bold text-red-600">{{ slotProps.data.OpenCreQty }}</p>
                  <p class="font-bold">Total</p>
                  <p class="font-bold text-gray-600">{{ slotProps.data.Quantity }}</p> -->
                  <InputNumber
                    :min="0"
                    :max="slotProps.data.OnHandQty"
                    v-model="slotProps.data.Quantity"
                    inputClass="w-12"
                    :class="validacionCampoRequerido('Quantity', slotProps.data.Quantity) ? 'border-red-600' : 'border-gray-300'"
                  />
                </div>
              </template>
            </Column>
            <Column header="Prec. total" v-if="validarColumnasTabla('PriceTotal')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                {{ $h.formatCurrency(slotProps.data.UnitPrice * slotProps.data.Quantity) }}
              </template>
            </Column>
            <Column header="Seg. Ent." v-if="validarColumnasTabla('U_PHR_SeguiEntrega')" class="text-xs" headerClass="text-xs" style="max-width: 8rem">
              <template #body="slotProps">
                <Dropdown
                  panelClass="text-xs w-min" id="selectSeguimientoEntregas"
                  v-model="slotProps.data.U_PHR_SeguiEntrega"
                  filter
                  autoFilterFocus
                  selectOnFocus
                  :class="validacionCampoRequerido('U_PHR_SeguiEntrega', slotProps.data.U_PHR_SeguiEntrega) ? 'border-red-600' : 'border-gray-300'"
                  class="w-26 border rounded-md"
                  optionValue="Code"
                  optionLabel="Name"
                  :options="ordenesStore._listado_seguimientos_entrega"
                />
                <span v-if="validacionCampoRequerido('U_PHR_SeguiEntrega', slotProps.data.U_PHR_SeguiEntrega)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="No. Ent." v-if="validarColumnasTabla('U_PHR_NumEntregas')" class="text-xs w-min" headerClass="text-xs"  style="max-width: 8rem">
              <template #body="slotProps">
                <InputNumber
                  :min="0"
                  :id="slotProps.data.ItemCode + 'U_PHR_NumEntregas'"
                  v-model="slotProps.data.U_PHR_NumEntregas"
                  inputClass="w-12"
                  :class="validacionCampoRequerido('U_PHR_NumEntregas', slotProps.data.U_PHR_NumEntregas) ? 'border-red-600' : 'border-gray-300'"
                />
                <span v-if="validacionCampoRequerido('U_PHR_NumEntregas', slotProps.data.U_PHR_NumEntregas)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Cant. Pres." v-if="validarColumnasTabla('U_PHR_CtdPrescrita')" class="text-xs" headerClass="text-xs" style="max-width: 5rem">
              <template #body="slotProps">
                <InputNumber
                  :min="0"
                  id="integeronly"
                  inputClass="w-12"
                  v-model="slotProps.data.U_PHR_CtdPrescrita"
                  :class="validacionCampoRequerido('U_PHR_CtdPrescrita', slotProps.data.U_PHR_CtdPrescrita) ? 'border-red-600' : 'border-gray-300'"
                />
                <span v-if="validacionCampoRequerido('U_PHR_CtdPrescrita', slotProps.data.U_PHR_CtdPrescrita)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Fec. Pres." v-if="validarColumnasTabla('U_PHR_FecPrescrip')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                <input
                  type="date"
                  :max="dayjs().format('YYYY-MM-DD')"
                  v-model="slotProps.data.U_PHR_FecPrescrip"
                  :class="validacionCampoRequerido('U_PHR_FecPrescrip', slotProps.data.U_PHR_FecPrescrip) ? 'border-red-600' : 'border-gray-300'"
                  >
                <span v-if="validacionCampoRequerido('U_PHR_FecPrescrip', slotProps.data.U_PHR_FecPrescrip)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Frec." v-if="validarColumnasTabla('U_PHR_Frecuencia')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                <Dropdown
                  v-model="slotProps.data.U_PHR_Frecuencia"
                  :options="ordenesStore._listado_frecuencias"
                  optionLabel="Name"
                  optionValue="Code"
                  :class="validacionCampoRequerido('U_PHR_Frecuencia', slotProps.data.U_PHR_Frecuencia) ? 'border-red-600' : 'border-gray-300'"
                  filter
                  autoFilterFocus
                  panelClass="text-xs"
                  class="text-xs rounded-md"
                />
                <span v-if="validacionCampoRequerido('U_PHR_Frecuencia', slotProps.data.U_PHR_Frecuencia)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="D. tratam." v-if="validarColumnasTabla('U_PHR_DuraTratami')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                <Dropdown
                  optionValue="Code"
                  optionLabel="Name"
                  panelClass="text-xs"
                  filter
                  autoFilterFocus
                  v-model="slotProps.data.U_PHR_DuraTratami"
                  class="w-full border rounded-md"
                  :class="validacionCampoRequerido('U_PHR_DuraTratami', slotProps.data.U_PHR_DuraTratami) ? 'border-red-600' : 'border-gray-300'"
                  :options="ordenesStore._listado_duraciones_tratamiento"
                />
                <span v-if="validacionCampoRequerido('U_PHR_DuraTratami', slotProps.data.U_PHR_DuraTratami)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Registro Médico/ Nombre Médico" v-if="validarColumnasTabla('U_PHR_RegMed')" class="text-xs" headerClass="text-xs" style="min-width: 15rem">
              <template #body="slotProps">
                <AutoComplete
                  panelClass="text-xs w-20"
                  inputClass="text-xs border-0 m-0 ring-0 w-full h-full rounded-sm ring-red-600"
                  class="text-xs border m-0 ring-0 w-full h-full rounded-md"
                  :class="validacionCampoRequerido('U_PHR_RegMed', slotProps.data.U_PHR_RegMed) ? 'border-red-600' : 'border-gray-300'"
                  v-model="slotProps.data.U_PHR_RegMed"
                  :suggestions="listadoMedicos"
                  @complete="buscarMedicos($event)"
                  field='name'
                  :forceSelection="true"
                  placeholder="Mínimo 3 caracteres para buscar.."
                >
                  <template #item="slotProps">
                    <div style="text-wrap: wrap;">
                      <span>{{slotProps.item.name}}</span>
                    </div>
                  </template>
                </AutoComplete>
                <span v-if="validacionCampoRequerido('U_PHR_RegMed', slotProps.data.U_PHR_RegMed)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="IPS Prestadora Servicio" v-if="validarColumnasTabla('U_PHR_IPSPrest')" class="text-xs" headerClass="text-xs" style="min-width: 15rem">
              <template #body="slotProps">
                <AutoComplete
                  panelClass="text-xs w-20"
                  inputClass="text-xs border-0 m-0 ring-0 w-full h-full rounded-sm ring-red-600"
                  class="text-xs border m-0 ring-0 w-full h-full rounded-md"
                  :class="validacionCampoRequerido('U_PHR_IPSPrest', slotProps.data.U_PHR_IPSPrest) ? 'border-red-600' : 'border-gray-300'"
                  v-model="slotProps.data.U_PHR_IPSPrest"
                  :suggestions="listadoIps"
                  @complete="buscarIps($event)"
                  field='NomIps'
                  :forceSelection="true"
                  placeholder="Mínimo 3 caracteres para buscar.."
                >
                  <template #item="slotProps">
                    <div style="text-wrap: wrap;">
                      <span>{{slotProps.item.NomIps}}</span>
                    </div>
                  </template>
                </AutoComplete>
                <span v-if="validacionCampoRequerido('U_PHR_IPSPrest', slotProps.data.U_PHR_IPSPrest)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Siniestro" v-if="validarColumnasTabla('U_PHR_Siniestro')" class="text-xs w-min" headerClass="text-xs"  style="max-width: 8rem">
              <template #body="slotProps">
                <!-- <input type="number" v-model="slotProps.data.U_PHR_Siniestro" /> -->
                <p v-if="slotProps">
                  {{ ordenesStore._udf.siniestro }}
                </p>
                <!-- <span v-if="validacionCampoRequerido('U_PHR_Siniestro', slotProps.data.U_PHR_Siniestro)" class="text-red-600 italic">Este campo es requerido</span> -->
              </template>
            </Column>
            <Column header="No. Auto." v-if="validarColumnasTabla('U_PHR_NumAutoriza')" class="text-xs w-min" headerClass="text-xs"  style="max-width: 8rem">
              <template #body="slotProps">
                <!-- <InputNumber
                  :class="validacionCampoRequerido('U_PHR_NumAutoriza', slotProps.data.U_PHR_NumAutoriza) ? 'border-red-600' : 'border-gray-300'"
                  v-model="slotProps.data.U_PHR_NumAutoriza"
                /> -->
                <p v-if="slotProps">{{ ordenesStore._udf.numero_autorizacion }}</p>
                <!-- <span v-if="validacionCampoRequerido('U_PHR_NumAutoriza', slotProps.data.U_PHR_NumAutoriza)" class="text-red-600 italic">Este campo es requerido</span> -->
              </template>
            </Column>
            <Column header="Fec. Auto." v-if="validarColumnasTabla('U_PHR_FecAutoriza')" class="text-xs w-min" headerClass="text-xs"  style="max-width: 8rem">
              <template #body="slotProps">
                <input
                  type="date"
                  :max="dayjs().format('YYYY-MM-DD')"
                  v-model="slotProps.data.U_PHR_FecAutoriza"
                  :class="validacionCampoRequerido('U_PHR_FecAutoriza', slotProps.data.U_PHR_FecAutoriza) ? 'border-red-600' : 'border-gray-300'"
                >
                <span v-if="validacionCampoRequerido('U_PHR_FecAutoriza', slotProps.data.U_PHR_FecAutoriza)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Fec. Prescrip. Mipres"  v-if="validarColumnasTabla('U_PHR_FecPres')" class="text-xs w-min" headerClass="text-xs"  style="max-width: 8rem">
              <template #body="slotProps">
                <input
                  type="date"
                  v-model="slotProps.data.U_PHR_FecPres"
                  :class="validacionCampoRequerido('U_PHR_FecPres', slotProps.data.U_PHR_FecPres) ? 'border-red-600' : 'border-gray-300'"
                >
                <span v-if="validacionCampoRequerido('U_PHR_FecPres', slotProps.data.U_PHR_FecPres)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Junta médica" v-if="validarColumnasTabla('U_PHR_JunMedi')" class="text-xs" headerClass="text-xs" style="max-width: 5rem">
              <template #body="slotProps">
                {{ validarColumnasTabla('U_PHR_JunMedi') }}
                <Dropdown
                  v-model="slotProps.data.U_PHR_JunMedi"
                  panelClass="text-xs"
                  style="max-width: 4.5rem"
                  class=" border rounded-md"
                  optionLabel="name"
                  optionValue="value"
                  :class="validacionCampoRequerido('U_PHR_JunMedi', slotProps.data.U_PHR_JunMedi) ? 'border-red-600' : 'border-gray-300'"
                  :options="arrSiNo"
                />
                <span v-if="validacionCampoRequerido('U_PHR_JunMedi', slotProps.data.U_PHR_JunMedi)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <template #expansion="slotProps">
              <DataTable
                v-if="slotProps.data.StockMovements.length"
                :value="slotProps.data.StockMovements"
                responsiveLayout="scroll"
                editMode="cell"
                class="tablaLotes"
                :headerCheckboxToggleAllDisabled="true"
              >
                <Column header="Información del lote" class="text-xs" headerStyle="background: white;" style="width: 28rem;">
                  <template #body="slotPropsChild">
                    <div class="flex gap-2">
                      <button @click="eliminarLotes(slotProps.data, slotPropsChild.data)"><i class="pi pi-trash text-red-600"></i></button>
                      <p class="font-bold">Lote:</p>
                      <p>{{ slotPropsChild.data.BatchNumber }}</p>
                      <p class="font-bold">Cant. Stock:</p>
                      <p>{{ slotPropsChild.data.cantstock }}</p>
                    </div>
                  </template>
                </Column>
                <Column field="Quantity" header="Cantidad entregar" class="text-xs" headerStyle="background: white;" headerClass="bg-white">
                  <template #body="slotPropsChild">
                    <div class="flex gap-2">
                      <input type="number" class="border rounded-md p-1" :min="0" :id="slotPropsChild.data.Id" @change="validarCantidadesIngresadas(slotPropsChild.data, slotProps.data, $event)" v-model="slotPropsChild.data.Quantity" />
                      <p class="font-bold text-blue-600 bg-blue-200 rounded-sm p-1 italic" v-if="slotPropsChild.data.apartado">Este medicamento es apartado</p>
                    </div>
                  </template>
                </Column>
              </DataTable>
              <p class="font-bold text-xs" v-else>No existen lotes seleccionados</p>
            </template>
          </DataTable>
          <div class="w-full grid grid-cols-1 lg:grid-cols-3 mt-3">
            <div>
              <p class="text-gray-600 text-xs w-full">Comentarios:</p>
              <Textarea v-model="ordenesStore.footer.comentarios" class="w-full h-5/6" />
            </div>
            <div class="w-full flex lg:px-10 mt-3">
              <div class="w-6/12">
                <p class="text-xs text-gray-600">Creado por:</p>
                <p class="font-bold">{{ persona.full_name }}</p>
              </div>
              <div class="w-6/12">
                <p class="text-xs text-gray-600">Actualizado por:</p>
                <p class="font-bold">{{ $route.name === 'ut-segupharma.ventas.ov.editar' ? persona.full_name : '' }}</p>
              </div>
            </div>
            <div class="md:flex mt-2 gap-2 items-center">
              <Button label="CREAR OV" @click="crearOv" class="p-button-success w-full font-bold mt-2 md:mt-0 h-min" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog headerClass="text-xs" header="Lotes disponibles" :closable="false" v-model:visible="displayModalMedicamentosAgrupados" :style="{width: '40vw'}" :modal="true">
      <div class="text-xs">
        <p>La cantidad que deseas ingresar es mayor a la cantidad en stock de este Lote, selecciona una agrupación para completar</p>
        <p class="my-2">Artículos pendientes por completar: <span class="font-bold" :class="cantidadFaltante() === 0 ? 'text-green-600' : 'text-red-600'">{{ cantidadFaltante() }}</span></p>
        <div class="border-6 border-b border-green-600" v-if="medicamentosAgrupados.filter(a => a.CodMx === medicamentoCompletarAgrupados.ItemCode).length">
          <div v-for="medicamento of medicamentosAgrupados.filter(a => a.CodMx === medicamentoCompletarAgrupados.ItemCode)" :key="medicamento.Id" class="p-field-checkbox mt-2">
            <div class="flex gap-2 text-xs p-1">
              <Checkbox :id="medicamento.NumLote" name="medicamento" :value="medicamento" v-model="medicamentosAgrupadosIngresar" />
              <div>
                <label>{{ medicamento.CodMx + ' - ' + medicamento.ItemName }}</label>
                <div class="flex gap-2">
                  <p class="font-bold">Lote: {{ medicamento.NumLote }}</p>
                  <p class="font-bold">Fecha de vencimiento: {{ medicamento.FecVcto }}</p>
                  <p class="font-bold">Stock: {{ medicamento.cantstock }}</p>
                </div>
              </div>
              <div class="block" v-if="medicamentosAgrupadosIngresar.some(a => a.NumLote === medicamento.NumLote)">
                <InputNumber :min="0" inputClass="p-inputtext-sm w-12" :max="medicamento.cantstock" class="w-10 p-inputtext-sm" v-model="medicamento.cantidadAgg" />
              </div>
            </div>
          </div>
          <div class="p-1 gap-1 flex justify-end">
            <i class="pi pi-arrow-up text-xs text-green-600"></i>
            <p class="italic text-green-600">Estos artículos coinciden con el codigo del artículo que necesitas completar</p>
          </div>
        </div>
        <div v-if="medicamentosAgrupados.filter(a => a.CodMx !== medicamentoCompletarAgrupados.ItemCode).length">
          <div class="p-1 flex gap-1 justify-end">
            <i class="pi pi-arrow-down text-xs text-blue-600"></i>
            <p class="italic text-blue-600">Estos artículos contienen el mismo principio activo del artículo a completar</p>
          </div>
          <div v-for="medicamento of medicamentosAgrupados.filter(a => a.CodMx !== medicamentoCompletarAgrupados.ItemCode)" :key="medicamento.Id" class="p-field-checkbox mt-2">
            <div class="flex gap-2 text-xs p-1">
              <Checkbox :id="medicamento.NumLote" name="medicamento" :value="medicamento" v-model="medicamentosAgrupadosIngresar" />
              <div>
                <label>{{ medicamento.CodMx + ' - ' + medicamento.ItemName }}</label>
                <div class="flex gap-2">
                  <p class="font-bold">Lote: {{ medicamento.NumLote }}</p>
                  <p class="font-bold">Fecha de vencimiento: {{ medicamento.FecVcto }}</p>
                  <p class="font-bold">Stock: {{ medicamento.cantstock }}</p>
                </div>
              </div>
              <div class="block" v-if="medicamentosAgrupadosIngresar.some(a => a.NumLote === medicamento.NumLote)">
                <InputNumber :min="0" inputClass="p-inputtext-sm w-12" :max="medicamento.cantstock" class="w-10 p-inputtext-sm" v-model="medicamento.cantidadAgg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button v-if="cantidadFaltante() === 0" @click="ingresarMedicamentosAgrupados" label="Guardar agrupaciones" />
        <Button v-if=" (cantidadFaltante() > 0) && (medicamentosAgrupados.length === medicamentosAgrupadosIngresar.length) && !medicamentosAgrupadosIngresar.some(a => a.cantidadAgg !== a.cantstock)" @click="ingresarMedicamentosAgrupados(cantidadFaltante())" :label="`Guardar y crear ${cantidadFaltante()} pendientes`" />
        <Button class="p-button-secondary" @click="cerrarModalAgrupados" label="Cancelar" />
      </template>
    </Dialog>
    <Dialog headerClass="text-xs" header="Cantidad del artículo" :closable="false"
            v-model:visible="displayModalMedicamentoPendiente" :style="{width: '30vw'}" :modal="true">
      <p class="text-sm">Esta editando las cantidades del artículo {{ lotePendienteSeleccionado.ItemName }}</p>
      <div class="w-full grid grid-cols-2 gap-2 text-sm mt-4">
        <p>Entregado:</p>
        <p>{{ lotePendienteSeleccionado.DelivrdQty }}</p>
        <p>Pendiente:</p>
        <p>{{ lotePendienteSeleccionado.OpenCreQty }}</p>
        <p>Cantidad total:</p>
        <div class="flex gap-2">
          <div class="grid">
            <InputNumber class="w-24" inputClass="w-24 font-bold" :min="lotePendienteSeleccionado.DelivrdQty ? lotePendienteSeleccionado.DelivrdQty : 1" v-model="lotePendienteSeleccionado.Quantity"/>
            <span class="w-full text-xs italic text-gray-600">La cantidad minima ingresada debe ser la entregada, si deseas reducir entregados debes realizar la devolución</span>
          </div>
        </div>
      </div>
      <template #footer>
        <Button :disabled="lotePendienteSeleccionado.Quantity === (lotePendienteSeleccionado.DelivrdQty + lotePendienteSeleccionado.OpenCreQty)" @click="actualizarPendiente" label="Actualizar cantidad pendiente"/>
        <Button class="p-button-outlined p-button-secondary" @click="cerrarModalPendiente"
                label="Cancelar"/>
      </template>
    </Dialog>
    <Dialog headerClass="text-xs" header="Artículos pendientes" :closable="false" v-model:visible="displayModalMedicamentosPendientesListado" :style="{width: '30vw'}" :modal="true">
      <p class="text-sm mb-4">Listado de medicamentos pendientes al crear esta orden</p>
      <div v-if="listadoPendientesTotales.length">
        <div class="text-sm" v-for="(medicamento, i) in listadoPendientesTotales" :key="i">
          <div class="flex my-2 items-center">
            <p class="w-9/12">
              {{ medicamento.ItemCode + ' - ' + medicamento.ItemName }}
            </p>
            <div class="grid grid-cols-2 w-3/12">
              <InputNumber :min="1" inputClass="w-20 p-2" v-model="medicamento.OpenCreQty" />
              <button @click="eliminarPendiente(medicamento, true)"><i class="pi pi-trash text-red-600"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="font-bold">No cuentas con medicamentos pendientes</p>
      </div>
      <template #footer>
        <Button @click="actualizarListadoMedicamentosPendientes" label="Actualizar cantidades pendientes" />
        <Button class="p-button-outlined p-button-secondary" @click="cerrarModalListadoMedicamentosPendientes" label="Cancelar" />
      </template>
    </Dialog>
    <Dialog headerClass="text-xs" header="Reemplazar artículo" :closable="false" v-model:visible="displayModalReemplazarMd" :style="{width: '30vw'}" :modal="true">
      <div class="w-full">
        <div class="w-full">
          <AutoComplete
            @item-select="reemplazarMedicamento(medicamentoSeleccionado)"
            v-model="medicamentoSeleccionado"
            placeholder="Buscar medicamento (mínimo 3 caracteres)..."
            id="inputElement"
            inputClass="w-full text-xs"
            v-tooltip.top="'Para activar el buscador (Ctrl + y)'"
            panelClass="text-xs w-20"
            class="p-inputtext-sm my-4 w-full text-xs"
            :suggestions="medicamentosStore._listado_medicamentos.filter(a => a.CodMx !== medicamentoAReemplazar.ItemCode && a.cantstock > 0)"
            autofocus
            @complete="buscarMedicamentos($event)"
            :forceSelection="true"
            optionLabel="name"
          >
          <template #item="slotProps">
            <div
            class="flex gap-2 items-center p-1 rounded-md bg-green-100"
            >
              <img class="w-10" alt="Imagen del artículo" :src="`${baseUrl}/articulos/get-image/${slotProps.item.PicturName}`">
              <div v-if="slotProps.item.cantstock">
                <div>
                  <p class="p-0" style="text-wrap: wrap">
                    {{ slotProps.item.CodMx + ' - ' + slotProps.item.ItemName + slotProps.item.UserText }}
                  </p>
                </div>
                <div class="flex items-center flex-wrap gap-x-1">
                  <p class="font-bold">Fecha de vencimiento:</p>
                  <p class="font-medium">{{ dayjs(slotProps.item.FecVcto).format('DD-MM-YYYY') }}</p>
                  <p class="font-bold">Lote:</p>
                  <p class="font-medium">{{ slotProps.item.NumLote }}</p>
                  <p class="font-bold">Cant Stock:</p>
                  <p class="font-medium">{{ slotProps.item.cantstock }}</p>
                  <p class="font-bold">Precio:</p>
                  {{ slotProps.item.Price }}
                  <p :class="slotProps.item.Price ? 'text-green-600' : ''"
                      class="font-medium">{{
                      slotProps.item.Price ? $h.formatCurrency(slotProps.item.Price) : '...ESTE ARTÍCULO NO CUENTA CON CONTRATO PRECIO'
                    }}
                  </p>
                  <p class="font-bold">Cum:</p>
                  <p class="font-medium">{{ slotProps.item.SWW }}</p>
                </div>
              </div>
              <div v-else>
                <p class="p-0">{{ slotProps.item.CodMx + ' - ' + slotProps.item.ItemName }}</p>
                <div class="flex gap-0 flex-wrap">
                  <p class="font-bold">Lote:</p>
                  <p class="font-medium">{{ slotProps.item.NumLote ? slotProps.item.NumLote : 'NO TIENE'  }}</p>
                  <p class="font-bold">Cant Stock:</p>
                  <p class="font-medium">0</p>
                </div>
              </div>
            </div>
          </template>
          </AutoComplete>
        </div>
      </div>
      <template #footer>
        <Button class="p-button-outlined p-button-secondary" @click="cerrarDisplayReemplazarMedicamento"
                label="Cancelar"/>
      </template>
    </Dialog>
    <Dialog :closable="false" v-model:visible="displayModalMedicamentosApartados" header="Artículos apartados" :style="{width: '30vw'}" :modal="true">
      <p>Este artículo se encuentra apartado, por favor seleccione la orden de la que desea tomar este apartado</p>
      <div v-for="medicamento of mdApartados" :key="medicamento.Id" class="p-field-checkbox mt-2">
        <div class="flex gap-2 text-xs p-1">
          <Checkbox  :id="medicamento.Id" name="medicamento" :value="medicamento" v-model="apartadosSend" class="my-auto"  />
          <div>
            <div class="flex gap-2">
              <p class="font-bold">Ov: {{ medicamento.OvId }}</p>
            </div>
            <label>{{ medicamento.ItemCode + ' - ' + medicamento.ItemName }}</label>
            <div class="flex gap-2">
              <p class="font-bold">Lote: {{ medicamento.NumLote }}</p>
              <p class="font-bold">Fecha de vencimiento: {{ medicamento.FecVcto }}</p>
              <p class="font-bold">CantidadRestante: {{ medicamento.CantidadRestante }}</p>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button :disabled="!apartadosSend.length" @click="seleccionarApartados" label="Aceptar" />
        <Button class="p-button-outlined p-button-secondary" @click="displayModalMedicamentosApartados = false, medicamentoSeleccionado = ''" label="No, Cancelar" />
      </template>
    </Dialog>
    <VerMedicamento />
  </section>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useMedicosStore } from '../../../../../stores/medicos.store'
import { useIpsStore } from '../../../../../stores/ips.store'
import { useBodegasStore } from '../../../../../stores/bodegas.store'
import { useStore } from '@/store'
import { useMedicamentosStore } from '../../../../../stores/medicamentos.store'
import { useOrdenesStore } from '../../../../../stores/ordenes.store'
import { usePacientesStore } from '../../../../../stores/pacientes.store'
import { useMipresStore } from '../../../../../stores/mipres.store'
import { useConfigGlobal } from '../../../../../stores/config-ov.store'
import VerMedicamento from '../../../../../components/verMedicamento.vue'
import Cookies from 'js-cookie'
import axios from 'axios'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
export default {
  name: 'bodyPrimeVue',
  components: {
    VerMedicamento
  },
  setup () {
    // Servicios
    const toast = useToast()
    // Stores
    const _ipsStore = useIpsStore()
    const _medicosStore = useMedicosStore()
    const storePersona = useStore()
    const bodegasStore = useBodegasStore()
    const medicamentosStore = useMedicamentosStore()
    const ordenesStore = useOrdenesStore()
    const mipresStore = useMipresStore()
    const pacientesStore = usePacientesStore()
    const router = useRouter()
    const configGlobal = useConfigGlobal()
    // Referencias
    const show = ref(true)
    const medicamentoSeleccionado = ref('')
    const displayModalReemplazarMd = ref(false)
    const numerosDireccionamiento = ref([])
    const displayModalMedicamentosAgrupados = ref(false)
    const displayModalMedicamentoPendiente = ref(false)
    const displayModalMedicamentosPendientesListado = ref(false)
    const displayModalMedicamentosApartados = ref(false)
    const medicamentosAgrupados = ref([])
    const medicamentosAgrupadosIngresar = ref([])
    const conteoMedicamentosPendientes = ref(0)
    const lotePendienteSeleccionado = ref()
    const listadoPendientesTotales = ref([])
    const medicamentoAReemplazar = ref()
    const modalEditUnidades = ref(false)
    const unidadEditar = ref(null)
    const mdApartados = ref({})
    const apartadosSend = ref([])
    const op = ref()
    const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_CONFIG_OV
    const medicamentoCompletarAgrupados = ref({})
    const lineasMdsSeleccionadas = ref([])
    const lineasLotesSeleccionados = ref([])
    const medicamentoTemporalIngresarApartado = ref({})
    // Data referencias
    const persona = storePersona.getters['auth/getPeople']
    const expandedRows = ref(null)
    const opcionesExonera = ref([
      {
        name: 'Exonera',
        id: '01'
      },
      {
        name: 'No exonera',
        id: '02'
      }
    ])
    const arrSiNo = ref([
      {
        name: 'Si',
        value: 'S'
      },
      {
        name: 'No',
        value: 'N'
      }
    ])
    // Computadas
    const rowData = computed(() => medicamentosStore._medicamentos_tabla)
    const bodegaSeleccionada = computed(() => bodegasStore._bodega_seleccionada)
    const listadoMedicos = computed(() => _medicosStore.listado_medicos)
    const listadoIps = computed(() => _ipsStore.listado_ips)
    const creandoOrden = computed(() => ordenesStore._creando_orden)
    const codigosMipres = computed(() => mipresStore._listado_codigos)
    const columnasTabla = computed(() => configGlobal._columnas_tabla)
    // Metodos
    const buscarMedicamentos = (event) => {
      if (!ordenesStore._header.cliente) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un cliente para continuar', life: 5000 })
      if (!ordenesStore._header.bodega) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar una bodega para continuar', life: 5000 })
      if (!pacientesStore._paciente_seleccionado) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un paciente para continuar', life: 5000 })
      const object = {
        CardCode: ordenesStore._header.cliente,
        WhsCode: bodegaSeleccionada.value.WhsCode
      }
      if (event.query.length > 2) {
        medicamentosStore.getListadoMedicamentos(object, event.query.toUpperCase())
      }
    }
    const validarIngresoMedicamento = async (medicamento) => {
      // Validación de medicamento sin contrato precio
      if (!medicamento.Price) {
        medicamentoSeleccionado.value = ''
        return toast.add({
          severity: 'error',
          summary: 'Error',
          detail: `El artículo ${medicamento.ItemName}
          no cuenta con precio, por favor valide con
          el contrato asociado al cliente ${ordenesStore._header.cliente}
          y vuelva a ingresar`,
          life: 10000
        })
      }
      let lote = {}
      if (medicamento.cantstock) {
        lote = {
          BatchNumber: medicamento.NumLote,
          FecVcto: medicamento.FecVcto,
          Quantity: 0,
          cantstock: medicamento.cantstock,
          ItemCode: medicamento.CodMx,
          WhsCode: bodegasStore._bodega_seleccionada.WhsCode,
          ObjectType: 15
        }
      }
      // Validación de entrega de un mismo medicamento en menos de 25 dias
      medicamentosStore.validarMedicamentosEntregadosMenores25Dias({ mx: medicamento.CodMx, U_ACS_TpoIdentf: pacientesStore._paciente_seleccionado.U_ACS_TpoIdentf, U_PHR_NumDcto: pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf }).then((data) => {
        if (+data.count > 0) {
          toast.add({
            severity: 'warn',
            summary: 'Advertencia',
            detail: `${pacientesStore._paciente_seleccionado.U_ACS_Sexo === 'Femenino' ? 'La' : 'El'}
            paciente ${pacientesStore._paciente_seleccionado.U_ACS_NmbPct}
            recibio el medicamento ${object.name} hace menos de 25 dias`,
            life: 10000
          })
        }
      })
      // Buscar medico e ips del resto de medicamentos ingresados
      let medico = null
      let ips = null
      if (medicamentosStore.medicamentos_tabla.length) {
        for (const i of medicamentosStore.medicamentos_tabla) {
          i.U_PHR_RegMed ? medico = i.U_PHR_RegMed : medico = null
          i.U_PHR_IPSPrest ? ips = i.U_PHR_IPSPrest : ips = null
        }
      }
      const object = {
        ItemCode: medicamento.CodMx,
        ItemName: medicamento.ItemName,
        GrpCode: medicamentoSeleccionado.value.GrpCode,
        UseBaseUnits: medicamento.UnitMedida[0].code,
        UnitsOfMeasurment: medicamento.UnitMedida[0].value,
        WarehouseCode: bodegasStore._bodega_seleccionada.WhsCode,
        UnitPrice: medicamento.Price ? parseInt(medicamento.Price) : null,
        U_PHR_SeguiEntrega: null,
        U_PHR_NumEntregas: 0,
        U_PHR_Exonerado: null,
        U_PHR_CuotaRecupe: null,
        U_PHR_CtdPrescrita: 0,
        U_PHR_FecPrescrip: '',
        U_PHR_Frecuencia: null,
        U_PHR_DuraTratami: null,
        U_PHR_CdHomologo: null,
        U_PHR_NomHomologo: null,
        U_PHR_CntHomologo: null,
        U_PHR_PrHomologo: null,
        U_PHR_TotalHomologo: null,
        U_PHR_NumAutoriza: 0,
        U_PHR_FecAutoriza: '',
        U_PHR_NoAcCTC: null,
        U_PHR_FchAcCTC: null,
        U_PHR_FchSolActCTC: null,
        U_PHR_CodMipres: null,
        U_PHR_NumDirec: null,
        U_PHR_FecPres: '',
        U_PHR_JunMedi: null,
        U_PHR_Siniestro: null,
        CostingCode: bodegaSeleccionada.value.U_PHR_CentroCosto,
        U_PHR_Sta_Pen: null,
        FreeText: null,
        U_PHR_IdPrincipal: null,
        LineNum: medicamentosStore._medicamentos_tabla.length ? medicamentosStore._medicamentos_tabla.length + 1 : 1,
        LineStatus: medicamento.cantstock ? 1 : 0,
        U_PHR_RegMed: medico,
        U_PHR_IPSPrest: ips,
        DelivrdQty: 0,
        OpenCreQty: 0,
        Quantity: 0,
        unidades_medida: medicamento.UnitMedida,
        unidadSeleccionada: { ...medicamento.UnitMedida[0] },
        name: medicamento.name,
        StockMovements: medicamento.cantstock ? [lote] : [],
        apartados: [],
        OnHandQty: medicamento.cantstock ? medicamento.cantstock : 0,
        SWW: medicamento.SWW
      }
      // Validacion de agrupados si el medicamento no tiene stock
      if (!medicamento.cantstock) {
        const agrupados = await _obtenerAgrupados(medicamento.GrpCode)
        if (agrupados.length) {
          const agrupado = agrupados[0]
          Swal.fire({
            title: 'Atención',
            text: `El artículo que intentas ingresar
            no cuenta con stock pero existe un artículo
            agrupado con stock, ¿Deseas ingresar el artículo agrupado ${agrupado.ItemCode}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Agregar',
            denyButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              const loteAgrupado = {
                BatchNumber: medicamento.cantstock > 0 ? medicamento.NumLote : null,
                Quantity: 0,
                cantstock: medicamento.cantstock,
                ItemCode: medicamento.CodMx,
                WhsCode: bodegasStore._bodega_seleccionada.WhsCode,
                ObjectType: 15
              }
              object.NumLote = agrupado.NumLote
              object.ItemCode = agrupado.CodMx
              object.UnitPrice = agrupado.Price
              object.OnHandQty = agrupado.cantstock
              object.ItemName = agrupado.ItemName
              object.FecVcto = agrupado.FecVcto
              object.name = agrupado.name
              object.UseBaseUnits = agrupado.UnitMedida[0].code
              object.UnitsOfMeasurment = agrupado.UnitMedida[0].value
              object.unidadSeleccionada = agrupado.UnitMedida[0]
              object.ItemDescription = agrupado.UserText
              object.unidades_medida = agrupado.UnitMedida
              object.U_PHR_Nivel = agrupado.U_PHR_Nivel
              object.pendiente = false
              object.LineStatus = 1
              object.LineNum = medicamentosStore._medicamentos_tabla.length ? medicamentosStore._medicamentos_tabla.length + 1 : 1
              object.StockMovements = [loteAgrupado]
              ingresarMedicamentoTabla(object, lote)
            } else {
              medicamentoSeleccionado.value = ''
            }
          })
        }
      }
      ingresarMedicamentoTabla(object, lote)
    }
    const ingresarMedicamentoTabla = (medicamento, lote) => {
      if (medicamentosStore._medicamentos_tabla.length && medicamentosStore._medicamentos_tabla.some(a => a.ItemCode === medicamento.ItemCode)) {
        medicamentosStore._medicamentos_tabla.map(a => {
          if (a.ItemCode === medicamento.ItemCode) {
            a.StockMovements.push(lote)
            a.OnHandQty += lote.cantstock
          }
        })
      } else {
        if (lote.cantstock) {
          medicamentosStore.medicamentos_tabla.splice(0, 0, medicamento)
        } else {
          asignarPendiente(medicamento, 1)
        }
      }
      _sumarCantidades(medicamento)
      medicamentoSeleccionado.value = ''
    }
    const asignarPendiente = (medicamento, cantidad) => {
      Swal.fire({
        title: 'Advertencia',
        text: `La cantidad que deseas ingresar es superior a la cantidad en stock general de la bodega de ${medicamento.ItemName}, deseas crear un pendiente por ${cantidad}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Crear pendiente',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          if (medicamentosStore._medicamentos_tabla.length && medicamentosStore._medicamentos_tabla.some(a => a.ItemCode === medicamento.ItemCode)) {
            if (medicamento.OpenCreQty) {
              Swal.fire({
                title: 'Advertencia',
                text: `El medicamento ${medicamento.ItemName}, ya cuenta con una cantidad pendiente, deseas modificarla?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Modificar pendiente',
                cancelButtonText: 'Cancelar'
              }).then((result2) => {
                if (result2.isConfirmed) {
                  medicamentosStore._medicamentos_tabla.map(a => {
                    if (a.ItemCode === medicamento.ItemCode) {
                      a.OpenCreQty = cantidad
                    }
                  })
                }
              })
            } else {
              medicamentosStore._medicamentos_tabla.map(a => {
                if (a.ItemCode === medicamento.ItemCode) {
                  a.OpenCreQty = cantidad
                }
              })
            }
          } else {
            medicamento.Quantity += cantidad
            medicamento.OpenCreQty = cantidad
            medicamento.apartados = []
            medicamentosStore.medicamentos_tabla.splice(0, 0, medicamento)
          }
          _sumarCantidades(medicamento)
          Swal.fire(
            'Guardado',
            'Pendiente creado con éxito',
            'success'
          )
        }
      })
    }
    const seleccionarApartados = () => {
      const array = []
      for (const i of apartadosSend.value) {
        array.push({
          BatchNumber: i.NumLote,
          Quantity: 0,
          cantstock: i.CantidadRestante,
          ItemCode: i.CodMx,
          WhsCode: bodegasStore._bodega_seleccionada.WhsCode,
          apartado: true,
          ObjectType: 15
        })
      }
      medicamentoTemporalIngresarApartado.value.StockMovements = array
      for (const i of array) {
        ingresarMedicamentoTabla(medicamentoTemporalIngresarApartado.value, i)
      }
      displayModalMedicamentosApartados.value = false
      toast.add({ severity: 'info', summary: 'Atención', detail: 'Se ingreso un medicamento apartado', life: 5000 })
    }
    const validarCantidadesIngresadas = async (dataLote, medicamento, evento) => {
      if (dataLote.Quantity > dataLote.cantstock) {
        const cantidadPendiente = dataLote.Quantity - dataLote.cantstock
        if (medicamento.StockMovements.length === 1) {
          _validacionAgrupados(cantidadPendiente, medicamento, dataLote)
        } else {
          let cantidadRestante = 0
          medicamento.StockMovements.map(a => {
            if (!(a.BatchNumber === dataLote.BatchNumber)) {
              cantidadRestante += a.cantstock - a.Quantity
            }
          })
          if (cantidadRestante > 0) {
            Swal.fire({
              title: 'Atención',
              icon: 'question',
              text: 'Intentas ingresar una cantidad mayor a la cantidad en stock, deseas completar las cantidades requeridas con los demas lotes?',
              showCancelButton: true,
              confirmButtonText: 'Completar cantidades',
              cancelButtonText: 'Cancelar'
            }).then((result) => {
              if (result.isConfirmed) {
                if (cantidadRestante >= cantidadPendiente) {
                  let descuentoCantidad = cantidadPendiente
                  dataLote.Quantity = dataLote.cantstock
                  medicamento.StockMovements.map(a => {
                    while (descuentoCantidad > 0) {
                      if (a.BatchNumber !== dataLote.BatchNumber && a.Quantity !== a.cantstock) {
                        a.Quantity += 1
                        descuentoCantidad -= 1
                      }
                    }
                  })
                } else {
                  dataLote.Quantity = dataLote.cantstock
                  medicamento.StockMovements.map(a => {
                    a.Quantity = a.cantstock
                  })
                  _validacionAgrupados(cantidadPendiente - cantidadRestante, medicamento, dataLote)
                }
              } else {
                dataLote.Quantity = dataLote.cantstock
                toast.add({ severity: 'warn', summary: 'Alerta', detail: 'Se asigno la cantidad máxima del lote', life: 5000 })
              }
              _sumarCantidades(medicamento)
            })
          } else {
            _validacionAgrupados(cantidadPendiente, medicamento, dataLote)
          }
        }
      } else {
        if (dataLote.Quantity < dataLote.cantstock && medicamento.OpenCreQty) {
          Swal.fire({
            title: 'Advertencia!',
            icon: 'warning',
            text: 'Al disminuir la cantidad de este artículo se eliminaran los pendientes, ¿Estas seguro?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar pendientes',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              medicamento.OpenCreQty = 0
              _sumarCantidades(medicamento)
            } else {
              dataLote.Quantity = dataLote.cantstock
            }
          })
        }
        _sumarCantidades(medicamento)
      }
    }
    const _validacionAgrupados = async (pendientes, medicamento, dataLote) => {
      const agrupados = await _obtenerAgrupados(medicamento.GrpCode)
      const array = []
      if (agrupados.length) {
        for (const i of agrupados) {
          if (medicamentosStore.medicamentos_tabla.some(a => a.ItemCode === i.CodMx)) {
            if (!medicamentosStore.medicamentos_tabla.find(a => a.ItemCode === i.CodMx).StockMovements.some(a => a.BatchNumber === i.NumLote)) {
              array.push(i)
            }
          } else {
            array.push(i)
          }
        }
      }
      array.map(a => {
        a.cantidadAgg = 0
      })
      dataLote.Quantity = dataLote.cantstock
      if (agrupados.length) {
        medicamentoCompletarAgrupados.value = medicamento
        medicamentosAgrupados.value = agrupados
        conteoMedicamentosPendientes.value = pendientes
        displayModalMedicamentosAgrupados.value = true
      } else {
        asignarPendiente(medicamento, pendientes)
      }
    }
    const _obtenerAgrupados = async (agrupado) => {
      if (!ordenesStore._header.cliente) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un cliente para continuar', life: 5000 })
      if (!ordenesStore._header.bodega) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar una bodega para continuar', life: 5000 })
      if (!pacientesStore._paciente_seleccionado) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un paciente para continuar', life: 5000 })
      const obectGetAgrupados = {
        CardCode: ordenesStore._header.cliente,
        WhsCode: ordenesStore._header.bodega,
        Agrupado: agrupado
      }
      const data = await medicamentosStore.getListadoMedicamentos(obectGetAgrupados, '')
      const medicamentosAgrupadosDisponibles = []
      if (data.length) {
        for (const i of data) {
          if (i.cantstock > 0 && i.Price) {
            medicamentosAgrupadosDisponibles.push(i)
          }
        }
      }
      return Promise.resolve(medicamentosAgrupadosDisponibles)
    }
    const ingresarMedicamentosAgrupados = (pendientes) => {
      medicamentosAgrupadosIngresar.value.map(a => {
        const object = {
          BatchNumber: a.NumLote,
          Quantity: a.cantidadAgg,
          cantstock: a.cantstock,
          ItemCode: a.CodMx,
          WhsCode: bodegasStore._bodega_seleccionada.WhsCode,
          ObjectType: 15
        }
        ingresarMedicamentoTabla(medicamentoCompletarAgrupados.value, object)
      })
      if (pendientes > 0) {
        medicamentosStore.medicamentos_tabla.map(a => {
          if (a.ItemCode === medicamentoCompletarAgrupados.value.ItemCode) {
            a.OpenCreQty = pendientes
            _sumarCantidades(a)
          }
        })
      }
      Swal.fire(
        'Guardado!',
        'Agrupados ingresados con éxito!',
        'success'
      ).then(() => {
        cerrarModalAgrupados()
      })
    }
    const _sumarCantidades = (medicamento) => {
      medicamento.DelivrdQty = 0
      medicamento.StockMovements.map(a => {
        medicamento.DelivrdQty += a.Quantity
      })
      medicamento.Quantity = medicamento.DelivrdQty + medicamento.OpenCreQty
    }
    const cantidadFaltante = () => {
      if (medicamentosAgrupadosIngresar.value.length) {
        let cantidad = conteoMedicamentosPendientes.value
        medicamentosAgrupadosIngresar.value.map(a => {
          cantidad -= a.cantidadAgg
        })
        return cantidad <= 0 ? 0 : cantidad
      }
      return conteoMedicamentosPendientes.value
    }
    const cerrarModalAgrupados = () => {
      conteoMedicamentosPendientes.value = 0
      medicamentosAgrupadosIngresar.value = []
      medicamentosAgrupados.value = []
      displayModalMedicamentosAgrupados.value = false
    }
    const eliminarMedicamentos = () => {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'Esta seguro de eliminar este registro?',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          if (lineasMdsSeleccionadas.value.length) {
            const array = []
            for (const i of medicamentosStore._medicamentos_tabla) {
              if (!lineasMdsSeleccionadas.value.some(e => e.ItemCode === i.ItemCode)) {
                array.push(i)
              }
            }
            medicamentosStore.medicamentos_tabla = array
            lineasMdsSeleccionadas.value = []
          }
          Swal.fire(
            'Guardado!',
            'Lineas eliminadas con éxito!',
            'success'
          )
          expandedRows.value = medicamentosStore._medicamentos_tabla.filter(a => a.ItemCode)
        }
      })
    }
    const eliminarLotes = (medicamento, lote) => {
      Swal.fire({
        title: 'Advertencia!',
        text: `Esta seguro de eliminar el lote ${lote.BatchNumber} del medicamento ${medicamento.ItemName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          if (medicamento.StockMovements.length === 1) {
            Swal.fire({
              title: 'Advertencia!',
              text: `Al eliminar este lote, eliminaras el medicamento ${medicamento.ItemName} y sus pendientes, ¿estas seguro de realizar esta acción?`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar'
            }).then((result2) => {
              if (result2.isConfirmed) {
                medicamentosStore.medicamentos_tabla = medicamentosStore._medicamentos_tabla.filter(a => a.ItemCode !== medicamento.ItemCode)
                Swal.fire(
                  'Guardado!',
                  'Medicamento eliminado con éxito!',
                  'success'
                )
                medicamento.OpenCreQty = 0
                _sumarCantidades(medicamento)
              }
            })
          } else {
            medicamentosStore._medicamentos_tabla.map(a => {
              a.StockMovements = a.StockMovements.filter(e => e.BatchNumber !== lote.BatchNumber)
            })
            medicamento.OpenCreQty = 0
            _sumarCantidades(medicamento)
            Swal.fire(
              'Guardado!',
              'Lote eliminado con éxito!',
              'success'
            )
          }
        }
      })
    }
    const validarReemplazarMedicamentoAdvertencia = (medicamento) => {
      const porEliminar = medicamento.StockMovements ? medicamento.StockMovements : []
      if (medicamento.OpenCreQty) {
        porEliminar.push({
          Quantity: medicamento.OpenCreQty
        })
      }
      Swal.fire({
        title: 'Advertencia',
        icon: 'question',
        html: `
        <span>El artículo ${medicamento.ItemCode} - ${medicamento.ItemName} devolverá el stock entregado y eliminará pendientes.</span>
        <p>
          <table class="w-full m-4" style="border-collapse: collapse;border: 1px solid;">
            <thead style="border: 1px solid;">
              <tr>
                <td>Cantidad</td>
                <td>Estado</td>
              </tr>
            </thead>
            ${porEliminar.map(a => `<tr><td>${a.Quantity}</td><td>${a.BatchNumber ? 'Por entregar' : 'Pendientes' }</td></tr>`).join('')}
          </table>
        </p>
        <span>¿Esta seguro de reemplazar este artículo?</span>
        `,
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          medicamentoAReemplazar.value = medicamento
          displayModalReemplazarMd.value = true
        }
      })
    }
    const reemplazarMedicamento = (medicamento) => {
      Swal.fire(
        'Reemplazado',
        `Artículo reemplazado con exito por ${medicamentoAReemplazar.value.ItemCode}`,
        'success'
      ).then(() => {
        const object = { ...medicamentoAReemplazar.value }
        object.StockMovements = medicamento.cantstock ? [
          {
            BatchNumber: medicamento.NumLote,
            Quantity: 0,
            cantstock: medicamento.cantstock,
            ItemCode: medicamento.CodMx,
            WhsCode: bodegasStore._bodega_seleccionada.WhsCode,
            ObjectType: 15
          }
        ] : []
        object.ItemCode = medicamento.CodMx
        object.ItemName = medicamento.ItemName
        object.GrpCode = medicamento.GrpCode
        object.UseBaseUnits = medicamento.UnitMedida[0].code
        object.UnitsOfMeasurment = medicamento.UnitMedida[0].value
        object.UnitPrice = medicamento.Price ? parseInt(medicamento.Price) : null
        object.CostingCode = bodegaSeleccionada.value.U_PHR_CentroCosto
        object.LineNum = medicamentosStore._medicamentos_tabla.length ? medicamentosStore._medicamentos_tabla.length + 1 : 1
        object.LineStatus = medicamento.cantstock ? 1 : 0
        object.unidades_medida = medicamento.UnitMedida
        object.unidadSeleccionada = { ...medicamento.UnitMedida[0] }
        object.name = medicamento.name
        object.OnHandQty = medicamento.cantstock ? medicamento.cantstock : 0
        object.SWW = medicamento.SWW
        const index = medicamentosStore._medicamentos_tabla.findIndex(a => a.ItemCode === medicamentoAReemplazar.value.ItemCode)
        medicamentosStore.medicamentos_tabla.splice(index, 0, object)
        medicamentosStore.medicamentos_tabla = medicamentosStore._medicamentos_tabla.filter(a => a.ItemCode !== medicamentoAReemplazar.value.ItemCode)
        cerrarDisplayReemplazarMedicamento()
      })
    }
    const cerrarDisplayReemplazarMedicamento = () => {
      displayModalReemplazarMd.value = false
      medicamentoSeleccionado.value = ''
      medicamentoAReemplazar.value = {}
    }
    const obtenerDetalleMedicamento = (medicamento) => {
      medicamentosStore.$patch((state) => {
        state.display_ver_medicamento = true
      })
      medicamentosStore.findByItemCode(medicamento.ItemCode)
    }
    const abrirModalPendiente = (medicamento) => {
      displayModalMedicamentoPendiente.value = true
      lotePendienteSeleccionado.value = { ...medicamento }
    }
    const actualizarPendiente = () => {
      medicamentosStore.medicamentos_tabla.map(a => {
        if (a.ItemCode === lotePendienteSeleccionado.value.ItemCode) {
          a.OpenCreQty = lotePendienteSeleccionado.value.Quantity - lotePendienteSeleccionado.value.DelivrdQty
          a.Quantity = lotePendienteSeleccionado.value.DelivrdQty + a.OpenCreQty
        }
      })
      cerrarModalPendiente()
      Swal.fire(
        'Guardado!',
        'Pendiente actualizado con éxito!',
        'success'
      )
    }
    const cerrarModalPendiente = () => {
      displayModalMedicamentoPendiente.value = false
      lotePendienteSeleccionado.value = ''
    }
    const buscarMedicos = (event) => {
      if (event.query.length > 2) {
        _medicosStore.obtenerListadoMedicos(event.query, { limit: 20 })
      }
    }
    const buscarIps = (event) => {
      if (event.query.length > 2) {
        _ipsStore.obtenerListadoIps(event.query, { limit: 20 })
      }
    }
    const eliminarPendiente = (medicamento) => {
      Swal.fire({
        title: 'Advertencia',
        icon: 'warning',
        text: `¿Esta seguro de eliminar los pendientes del medicamento ${medicamento.ItemName}?`,
        showCancelButton: true,
        confirmButtonText: 'Eliminar'
      }).then((result) => {
        if (result.isConfirmed) {
          medicamentosStore._medicamentos_tabla.map(a => {
            if (a.ItemCode === medicamento.ItemCode) {
              a.OpenCreQty = 0
            }
          })
          displayModalMedicamentosPendientesListado.value = false
          Swal.fire(
            'Guardado',
            'Pendientes eliminados con éxito',
            'success'
          )
        }
      })
    }
    const validacionCampoRequerido = (campo, value) => {
      const valor = columnasTabla.value.find(a => a.ColName === campo)
      return !!(valor.IsRequired && !value && creandoOrden.value)
    }
    const validarColumnasTabla = (columna) => {
      if (columnasTabla.value.some(a => a.ColName === columna)) {
        return !columnasTabla.value.find(a => a.ColName === columna).campo_oculto
      } else {
        return false
      }
    }
    const rowClass = (data) => {
      return [{ 'row-temp-bg': data.ItemCode === rowData.value[0].ItemCode }]
    }
    const abrirModalListadoMedicamentosPendientes = () => {
      displayModalMedicamentosPendientesListado.value = true
      listadoPendientesTotales.value = []
      medicamentosStore._medicamentos_tabla.map(a => {
        if (a.OpenCreQty) {
          listadoPendientesTotales.value.push({ ...a })
        }
      })
    }
    const actualizarListadoMedicamentosPendientes = () => {
      displayModalMedicamentosPendientesListado.value = false
      Swal.fire(
        'Guardado!',
        'Pendientes actualizados con éxito!',
        'success'
      ).then(() => {
        listadoPendientesTotales.value.map(a => {
          const medicamento = medicamentosStore._medicamentos_tabla.find(e => e.ItemCode === a.ItemCode)
          medicamento.OpenCreQty = a.OpenCreQty
          console.log('el medicamento->', medicamento)
          _sumarCantidades(medicamento)
        })
      })
    }
    const cerrarModalListadoMedicamentosPendientes = () => {
      listadoPendientesTotales.value = []
      displayModalMedicamentosPendientesListado.value = false
    }
    const obtenerNumerosDireccionamiento = (event) => {
      const array = codigosMipres.value.filter(a => a.NoPrescripcion === event.value.NoPrescripcion)
      array.map(a => {
        a.name = a.IDDireccionamiento + ' Fec. max ent-> ' + a.FecMaxEnt
      })
      numerosDireccionamiento.value = array
    }
    const toggle = (event) => {
      op.value.toggle(event)
    }
    const crearOv = () => {
      if (!medicamentosStore._medicamentos_tabla.length) return Swal.fire('Advertencia!', 'La orden no se puede crear vacia, por favor ingresa un artículo para iniciar', 'error')
      Swal.fire({
        title: '¿Esta seguro?',
        icon: 'warning',
        text: '¿Desea confirmar la creación de la Orden de Venta?',
        showCancelButton: true,
        confirmButtonText: 'Si, crear',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          ordenesStore.creando_orden = true
          if (medicamentosStore._medicamentos_tabla.some(a => +a.Quantity <= 0)) {
            medicamentosStore._medicamentos_tabla.map(e => {
              if (!e.OnHandQty) {
                toast.add({ severity: 'error', summary: 'Error', detail: `El artículo ${e.ItemCode + ' - ' + e.ItemName} debe tener un pendiente`, life: 3000 })
              } else {
                toast.add({ severity: 'error', summary: 'Error', detail: `La cantidad del artículo ${e.ItemCode + ' - ' + e.ItemName} no puede ser 0`, life: 3000 })
              }
            })
          } else {
            ordenesStore.crearOrdenVenta().then(async (data) => {
              if (!data) return
              Swal.fire({
                title: 'Guardado',
                text: `Orden creada con exito con el numero ${data.Id}`,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Ir al listado',
                confirmButtonColor: '#1C3FAA',
                denyButtonText: 'Imprimir OV',
                denyButtonColor: 'purple',
                cancelButtonText: 'Crear mas ordenes',
                cancelButtonColor: '#3085d6',
                icon: 'success'
              }).then((result) => {
                if (result.isConfirmed) {
                  router.push({ name: 'ut-segupharma.ventas.ov.listado' })
                } else if (result.isDenied) {
                  const url = `${process.env.VUE_APP_MICROSERVICE_API_ORDENES_VENTA}/ov/generar-pdf/${data.Id}`
                  const token = JSON.parse(Cookies.get('user')).token
                  const res = axios.getUri({ url, params: { token: `Bearer ${token}` } })
                  window.open(res, '_blank')
                  router.push({ name: 'ut-segupharma.ventas.ov.listado' })
                } else {
                  ordenesStore.getUltimaOrden()
                }
                ordenesStore.resetData()
              })
            })
          }
        }
      })
    }
    // Activación buscador de medicamentos
    window.addEventListener(
      'keypress',
      (event) => {
        const keyName = event.key
        if (keyName === '\x19') {
          document.getElementById('inputElement').click()
        }
      },
      false
    )
    onMounted(() => {
      ordenesStore.listarSeguimientoEntregas()
      ordenesStore.listarDuracionesTratamiento()
      ordenesStore.listarFrecuencias()
    })
    return {
      show,
      rowData,
      buscarMedicamentos,
      medicamentoSeleccionado,
      displayModalReemplazarMd,
      dayjs,
      expandedRows,
      columnasTabla,
      arrSiNo,
      lineasMdsSeleccionadas,
      eliminarMedicamentos,
      listadoMedicos,
      buscarMedicos,
      buscarIps,
      listadoIps,
      codigosMipres,
      numerosDireccionamiento,
      persona,
      crearOv,
      opcionesExonera,
      creandoOrden,
      validacionCampoRequerido,
      bodegaSeleccionada,
      obtenerDetalleMedicamento,
      displayModalMedicamentosAgrupados,
      medicamentosAgrupados,
      medicamentosAgrupadosIngresar,
      conteoMedicamentosPendientes,
      displayModalMedicamentoPendiente,
      displayModalMedicamentosApartados,
      lotePendienteSeleccionado,
      abrirModalPendiente,
      cerrarModalPendiente,
      actualizarPendiente,
      displayModalMedicamentosPendientesListado,
      abrirModalListadoMedicamentosPendientes,
      listadoPendientesTotales,
      cerrarModalListadoMedicamentosPendientes,
      actualizarListadoMedicamentosPendientes,
      eliminarPendiente,
      rowClass,
      cerrarDisplayReemplazarMedicamento,
      obtenerNumerosDireccionamiento,
      medicamentoAReemplazar,
      reemplazarMedicamento,
      validarColumnasTabla,
      validarReemplazarMedicamentoAdvertencia,
      modalEditUnidades,
      unidadEditar,
      baseUrl,
      mdApartados,
      apartadosSend,
      seleccionarApartados,
      op,
      toggle,
      validarIngresoMedicamento,
      ordenesStore,
      validarCantidadesIngresadas,
      cantidadFaltante,
      medicamentoCompletarAgrupados,
      medicamentosStore,
      ingresarMedicamentosAgrupados,
      cerrarModalAgrupados,
      lineasLotesSeleccionados,
      eliminarLotes,
      pacientesStore
    }
  }
}
</script>

<style>
  ::-webkit-scrollbar {
    height: 0.3rem;
    width: 0.3rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  .row-temp-bg {
    animation-duration: 3s;
    animation-name: slidein;
    animation-play-state: running;
  }
  .h-panel {
    max-height: 300px!important;
  }

  @keyframes slidein {
    from {
      background: rgb(91, 195, 255);
    }

    to {
      background: white;
    }
  }
  :global(.swal2-container) {z-index: 1000000 !important}
</style>
