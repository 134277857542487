import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_CONFIG_OV

export default class MipresService {
  listarCodigos (params) {
    return http.get(`${baseUrl}/mipres-direccionamiento/search-by-patient`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
