
import { defineStore } from 'pinia'
import MipresService from '../services/mipres.service'
const _MipresService = new MipresService()
export const useMipresStore = defineStore('mipresStore', {
  state: () => ({
    listado_codigos: []
  }),
  getters: {
    _listado_codigos: state => state.listado_codigos
  },
  actions: {
    obtenerCodigos (params) {
      _MipresService.listarCodigos(params).then(({ data }) => {
        this.listado_codigos = data
      })
    }
  }
})
