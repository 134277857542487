
import { defineStore } from 'pinia'
import IpsService from '../services/ips.service'
const _IpsService = new IpsService()

export const useIpsStore = defineStore('ipsStore', {
  state: () => ({
    listado_ips: [],
    ips_seleccionada: {}
  }),
  getters: {
    _listado_ips: state => state.listado_ips,
    _ips_seleccionada: state => state.ips_seleccionada
  },
  actions: {
    obtenerListadoIps (name, params) {
      _IpsService.listarIps(name, params).then(({ data }) => {
        this.listado_ips = data
      })
    }
  }
})
