
import { defineStore } from 'pinia'
import MedicosService from '../services/medico.service'
const _MedicosService = new MedicosService()

export const useMedicosStore = defineStore('medicosStore', {
  state: () => ({
    listado_medicos: []
  }),
  getters: {
    _listado_medicos: (state) => state.listado_medicos
  },
  actions: {
    obtenerListadoMedicos (name, params) {
      _MedicosService.listarMedicos(name, params).then(({ data }) => {
        const array = []
        for (const i of data) {
          array.push({
            ...i,
            name: i.RegMedico + ' - ' + i.NomMedico
          })
        }
        this.listado_medicos = array
      })
    }
  }
})
